export const environment = {
	production: true,
	firebaseConfig: {
		apiKey: "AIzaSyCJ7dfTUJCeDiHOVMpi_mYSblC9uFLWzvI",
		authDomain: "yygp-1cf9b.firebaseapp.com",
		databaseURL: "https://yygp-1cf9b.firebaseio.com",
		projectId: "yygp-1cf9b",
		storageBucket: "yygp-1cf9b.appspot.com",
		messagingSenderId: "864395911386",
		appId: "1:864395911386:web:f16ded324383e654f745e3",
		measurementId: "G-6V3YFQ0L3G"
		/*
		apiKey: "AIzaSyCeTUMQUzZ1Cl8Qdm__xqy81XvVFfLTTQU",
	    authDomain: "directhire-9398b.firebaseapp.com",
	    databaseURL: "https://directhire-9398b.firebaseio.com",
	    projectId: "directhire-9398b",
	    storageBucket: "directhire-9398b.appspot.com",
	    messagingSenderId: "318840277527",
	    appId: "1:318840277527:web:39da93e976c7e5edbeea52"
		*/
	},  
};
