import { UserProfileService } from './../services/user-profile.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  userRole: string;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private uProfile: UserProfileService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
          this.setUpdateToken(token);
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  async requestPermission() {
    await this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.setUpdateToken(token);
      },
      (err) => {
        console.error('Error - ', err);
      }
    );
  }

  setUpdateToken(token: string){
    this.uProfile.userControl.subscribe(x => {
      this.userRole = x.userRole;
      if (this.userRole == 'erProfile') { 
        this.uProfile.afs.doc('erProfile/'+this.uProfile.userId+'/fcmToken/'+token).set({'Token': true})
        this.uProfile.afs.doc('erFcmToken/'+token).set({'Token': true});
      } else if (this.userRole == 'hpProfile') {
        this.uProfile.afs.doc('hpProfile/'+this.uProfile.userId+'/fcmToken/'+token).set({'Token': true})
        this.uProfile.afs.doc('hpFcmToken/'+token).set({'Token': true});
        /*
          hpNationality = 1 => Filipino
          hpNationality = 2 => Indonesia
        */
/*
        if (hpNationality == 1) {
          this.uProfile.afs.doc('hpPhiFcmToken/'+token).set({'Token': true});
        } else if (hpNationality == 2) {
          this.uProfile.afs.doc('hpIdnFcmToken/'+token).set({'Token': true});
        } else {
        }
*/
      } else if (this.userRole == 'admin') {
        this.uProfile.afs.doc('admin/'+this.uProfile.userId+'/fcmToken/'+token).set({'Token': true})
      } else {
        return;
      }
    });
  }


  async receiveMessage() {
    await this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }

}