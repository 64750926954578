import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  loginBoolean: BehaviorSubject<any> = new BehaviorSubject(0);
  lang: BehaviorSubject<string> = new BehaviorSubject('en');
  userControl: BehaviorSubject<any> = new BehaviorSubject('any');
  userProfileDetail: BehaviorSubject<any> = new BehaviorSubject([]);
  adminCtrlDetail: BehaviorSubject<any> = new BehaviorSubject([]);  

  public userCtrlRef: AngularFirestoreDocument<any>;
  public userRef: AngularFirestoreDocument<any>;
  public requestBadgeCtrlRef: AngularFirestoreDocument<any>;
  private _mainCtrl: Observable<any>;

  userId: string;
  userRoleStorage: boolean = false;
  userLangStorage: boolean = false;

  email: string;

  minSalary: number;
  minFoodAllowance: number;
  dhVersion: string;

  mainCtrlDetail: any;

  appCurrentVersion: string;

  requestCol: Observable<any>;
  ownRef: string;
  
  language: string;

  constructor(public afAuth: AngularFireAuth, 
              public afs: AngularFirestore,
              public translate: TranslateService,
              public storage: Storage,
              public navCtrl: NavController,
			) {    
    this.appCurrentVersion = 'Beta Version: 0.12';
    this.storage.get("language").then(res => {
      if (res) { 
        this.setDefaultLangFunction(res);
        this.userLangStorage = true;
      }
    });

    this.storage.get("userRole").then(res => {
      if (res) { 
        if (res == 'erProfile' || res == 'hpProfile' || res == 'admin') {
          this.userRoleStorage = true;
        }
      }
    });
/*

    Promise.all([this.storage.get("userRole"), this.storage.get("language")]).then(values => {
      if (values[0]) {
        if (values[0] == 'erProfile' || values[0] == 'hpProfile' || values[0] == 'admin') {
          this.userRoleStorage = true;
        }
        this.setUserRole(values[0]);
      }
      if (values[1]) {
        this.setDefaultLangFunction(values[1]);
      }
    });
*/
    this.afAuth.authState.subscribe( user => {
      if (user) {
        this.loginBoolean.next(2);
        this.userId = user.uid;
//        console.log(this.userId);
        this.email = user.email;
        this.userCtrlRef = this.afs.doc<any>(`control/`+this.userId);
        this.userCtrlRef.valueChanges().subscribe( res => {
          if (res) {
            this.userControl.next(res);
//            console.log(res.userRole);
            this.profileRef(res.userRole, this.userId);
            this.requestBadgeCtrlRef = this.afs.doc<any>(res.userRole+`/`+this.userId+`/requestBadge/requestBadgeControl`); // for update document purpose;
          }
          //userRole
          if (res) {
          } else {
            if (this.userRoleStorage == false) {
              this.navCtrl.navigateForward('/user-role');
            }
          }

          //userLang
          if (res) {
            if (this.userLangStorage == false) { this.setDefaultLangFunction(res.language); this.userLangStorage = true}
          } else {
            if (this.userLangStorage == false) {this.setInitialAppLanguage(); this.userLangStorage = true}
          }

        });
      } else {
        this.loginBoolean.next(1);
        if (this.userLangStorage == false) { 
          this.setInitialAppLanguage();
        }
      }
      this.setMainCtrl();
    });
  }

  async setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    if (language == 'en' || language == 'zh' || language == 'in' ) {
      this.language = language;
    } else {
      this.language = 'en';
    }
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    this.storage.set('language', this.language);
  }

  requestBadge(userRole: string, userId: string): Observable<any>{
    return this.afs.doc<any>(userRole+`/`+userId+`/requestBadge/requestBadgeControl`).snapshotChanges()
      .pipe(map(snap => {
          return {
            id: snap.payload.id,
            ...snap.payload.data()
          };
        })
      );
  }

  profileRef(userRole: string, userId: string){
    this.userRef = this.afs.doc<any>(userRole+`/`+userId);
    this.userRef.valueChanges().subscribe(res2 => {
      if (res2) {
        this.userProfileDetail.next(res2);
      }
    });    
  }

  async setDefaultLangFunction(i: string){
    this.storage.set('language', i);
    this.translate.setDefaultLang(i);
    this.translate.use(i);
  }

  signOut(){
    this.userRoleStorage = false;
    this.userLangStorage = false;
    this.storage.clear();    
    this.loginBoolean.next(0);
    this.lang.next('en');
    this.userControl.next('any');
    this.userProfileDetail.next([]);
    this.adminCtrlDetail.next([]);

    this.afAuth.auth.signOut();
    this.navCtrl.navigateRoot('');
  }

  async changeLanguage(i: string){
    this.lang.next(i);
    this.storage.set('language', i);
    this.userCtrlRef.update({'language': i});
  }

  setUserRole(i: string){
    this.storage.set('userRole', i);
  }

  setMainCtrl(){
    this._mainCtrl = this.afs.doc<any>('adminCtrl/IobyJ8HElCHOxBniYfE5').valueChanges();
    this._mainCtrl.subscribe(res2 => {
      this.adminCtrlDetail.next(res2);
      this.mainCtrlDetail = res2;
      this.minSalary = res2.minSalary;
      this.checkAppVersion(res2.ec5mVersion);
    }); 
  }

  checkAppVersion(ec5mVersion: string){
    this.storage.get('version').then((val) => {
      if (val) {
        if (val != ec5mVersion) { 
          this.storage.set('version', ec5mVersion).then(res => {
            if(confirm("New version available. Load New Version?")) {
              window.location.reload(true);
            }
          });
        }
      } else {
        this.storage.set('version', ec5mVersion);
      }
    });
  }

  async changeOnline(){
    const updateContent = { 'dhAvailable': true, 'yygpAvailable': true, 'visible': true};
    var batch = this.afs.firestore.batch();
    batch.update(this.userCtrlRef.ref, updateContent);
    batch.update(this.userRef.ref, updateContent);
    return batch.commit();
  }


}
