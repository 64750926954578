import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'agency-charges', loadChildren: () => import('./agency-charges/agency-charges.module').then(m => m.AgencyChargesPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'employer-detail/:ref', loadChildren: () => import('./employer-detail/employer-detail.module').then(m => m.EmployerDetailPageModule) },
  { path: 'helper-detail/:ref', loadChildren: () => import('./helper-detail/helper-detail.module').then(m => m.HelperDetailPageModule) },
  { path: 'home-after-login', loadChildren: () => import('./home-after-login/home-after-login.module').then(m => m.HomeAfterLoginPageModule) },
  { path: 'user-role', loadChildren: () => import('./user-role/user-role.module').then(m => m.UserRolePageModule) },
  { path: 'noti', loadChildren: () => import('./noti/noti.module').then(m => m.NotiPageModule) },
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule) },
  { path: 'sub-menu/:id', loadChildren: () => import('./sub-menu/sub-menu.module').then(m => m.SubMenuPageModule) },
  { path: 'reject-list', loadChildren: () => import('./reject-list/reject-list.module').then(m => m.RejectListPageModule) },
  { path: 'click-history', loadChildren: () => import('./click-history/click-history.module').then(m => m.ClickHistoryPageModule) },
  { path: 'employer-edit/:id', loadChildren: () => import('./employer-edit/employer-edit.module').then(m => m.EmployerEditPageModule) },
  { path: 'helper-edit/:id', loadChildren: () => import('./helper-edit/helper-edit.module').then(m => m.HelperEditPageModule) },
  { path: 'contact-way/:id', loadChildren: () => import('./contact-way/contact-way.module').then(m => m.ContactWayPageModule) },
  { path: 'exp-edit/:id', loadChildren: () => import('./exp-edit/exp-edit.module').then(m => m.ExpEditPageModule) },
  { path: 'exp-detail-edit/:userRef/:expRef', loadChildren: () => import('./exp-detail-edit/exp-detail-edit.module').then(m => m.ExpDetailEditPageModule) },
  { path: 'hired', loadChildren: () => import('./hired/hired.module').then(m => m.HiredPageModule) },
  { path: 'init-setup', loadChildren: () => import('./init-setup/init-setup.module').then(m => m.InitSetupPageModule) },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
